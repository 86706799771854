import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import DropDowns from "./DropDowns";
import swal from "sweetalert";
import useApiServices from "../../utils/useApiServices";
import {
  MASTER_MERCHANT_PHASE2,
  GET_ALL_VEHICLE_TYPE_WITH_ACTIVE,
  GET_ALL_VEHICLE_SUB_TYPE_WITH_ACTIVE,
  SETTLEMENT_TYPE_GET,
} from "../../utils/constant";
import RemoveIcon from '@mui/icons-material/Remove';

const offcanvasStyle = {
  width: '90vw', 
};


const ViewMasterMerchantPhase2 = ({id, show, onHide,refresh, masterMerchantX}) => {

    const [mastermerchantData, setMastermerchntData] = useState({});
    const [settlementData, setSettlementData] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);
    const [serviceDetails, setServiceDetails] = useState({});
    const [disabledFields, setDisabledFields] = useState({});
    const [formDataInputs, setFormDataInputs] = useState({
        masterMerchantName: "",
        settlementTypeId: "",
      });
    const [selectedType, setSelectedType] = useState({
      schemeId: "",
      aggeregatorId: "",
      masterMerchantId: "",
      switchId: "",
      vehicleName: "",
      bankId: "",
      schemeName: "",
      aggeregatorName: "",
      switchName: "",
      bankName: "",
      remark: "",
    });
  
    const offcanvasStyle = {
      width: "90vw",
    };
    const { apiCallFnforGet } = useApiServices();

    const getPhase2Data = async () => {
        try {
          const response = await apiCallFnforGet(MASTER_MERCHANT_PHASE2 + id);
          if (response.statusCode === 200) {
            const data = response.data;
            setMastermerchntData({
              masterMerchantName: data.masterMerchantName,
              settlementTypeName: data.settlementTypeName,
              services: data.services
            });
          } else {
            setMastermerchntData({});
          }
        } catch (error) {
          console.log(error);
        }
      };


    
      useEffect(() => {
        getPhase2Data();
      }, [id, refresh]);
    
  
    useEffect(() => {
      GetAllSettlementData();
      GetVehicleType();
    }, []);

    useEffect(() => {
        setFormDataInputs({
            masterMerchantId: mastermerchantData.masterMerchantId,
            settlementTypeName: mastermerchantData.settlementTypeName,
        });
      }, [mastermerchantData, id]);

    
  
    const GetAllSettlementData = async () => {
      try {
        const response = await apiCallFnforGet(SETTLEMENT_TYPE_GET);
        if (response.statusCode === 200) {
          setSettlementData(response?.data);
        } else {
          setSettlementData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const GetVehicleType = async () => {
      try {
        const response = await apiCallFnforGet(GET_ALL_VEHICLE_TYPE_WITH_ACTIVE);
        if (response.statusCode === 200) {
          setPaymentTypes(response?.data);
        } else {
          setPaymentTypes([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
console.log(paymentTypes ? paymentTypes: "")

const FindServiceType =(val)=>{
  const service = paymentTypes?.find((res) => 
    res.paymentSubTypedtos.find((obj) => obj.paymentSubTypeId === val)
  )?.paymentService;
  
  // console.log(service);
  return service?.toUpperCase();
}

// console.log(FindServiceType(12))
   

   
  
  return (
    <>

    <Offcanvas show={show} onHide={onHide} backdrop="static" keyboard={false} placement="end" style={offcanvasStyle}>
    <div className="d-flex justify-content-start align-items-center mt-3" style={{marginLeft:"28px"}}>

    <Offcanvas.Header closeButton >
    </Offcanvas.Header>
        <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">View Master Merchant Phase 2</h5>
    </div>
   
    <Offcanvas.Body >
    <div className="row">
            {/* <div className="col-12 d-flex justify-content-center"> */}
              {/* <div className="">
                <div className="card-body py-4"> */}
                  {/* <div id="" className="form-wizard order-create"> */}
                    <div id="" className="tab-pane" role="tabpanel">
                    {/* <div className="table-responsive"> */}
                      <div className="table table-bordered">
                      <div className="row fs-4">
                      {[
                          {
                            label: "Master Merchant Name",
                            value: mastermerchantData?.masterMerchantName || masterMerchantX?.name || "N/A",
                          },
                          {
                            label: "Settlement Type",
                            value: mastermerchantData?.settlementTypeName || "N/A",
                          },
                          ].map((item, index) => (
                            <div
                              key={index}
                              className="col-6 border-bottom border-dark"
                            >
                              <div className="d-flex justify-content-between align-items-center table table-bordered">
                                <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">{item.label} <RemoveIcon /></tr>
                                <tr className=" mt-2 w-100 justify-content-between text-end">{item.value}</tr>
                              </div>
                            </div>
                          ))}
                      </div>
                      </div>
                      {/* </div> */}

                      <div className="row">

                        <table className="table">
                        <thead>
                        <tr>
                          <th>Service Type</th>
                          <th>Sub Service Type</th>
                          <th>Fixed Amount (monthly)</th>
                          <th>Percentage (monthly)</th>
                          <th>MDR (monthly)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mastermerchantData.services?.map((service, i) => (
                          <tr key={i}>
                            <td  className="fw-bolder">{FindServiceType(service?.paymentSubTypeId)}</td>
                            <td>{service.paymentSubTypeType}</td>
                            <td>{service.fixAmount.toFixed(2)}</td>
                            <td>{service.percentage.toFixed(2)}</td>
                            <td>{service.mdrValue.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                        </table>

                      </div>
                    </div>
                  {/* </div> */}
                {/* </div>
              </div> */}
            {/* </div> */}
          </div>
    </Offcanvas.Body>
    </Offcanvas>
    </>
  );
};
export default ViewMasterMerchantPhase2;