import React, { useEffect, useState } from "react";
import { Card, Row, Offcanvas } from "react-bootstrap";
import {
  AGGREGATOR_UPDATE,
  GET_ALL_AGGREGATOR,
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
} from "../../utils/constant";
import swal from "sweetalert";
import { isEmailIdRegexIsValid, getKeyPressed } from "../../utils/helper";
import useApiServices from "../../utils/useApiServices";
import { useLocation } from "react-router-dom";

const offcanvasStyle = {
  width: "90vw", // Set width to 70vw
};

const MasterMerchantEdit = ({ id, show, onHide, setRefresh, refresh ,setCurrentId}) => {
  const [loading, setLoading] = useState(false);
  const [aggregatorData, setAggregatorData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");

  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    masterMerchantName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();
  const location = useLocation();
  // const AggregatorId = location.state.id;

  useEffect(() => {
    setFormDataInputs({
      AggregatorName: aggregatorData.name,
      masterMerchantName: aggregatorData.masterMerchantId,
      password: aggregatorData.password,
      MobileNumber: aggregatorData.mobile,
      Prifix: aggregatorData.midprifix,
      emailId: aggregatorData.email,
    });
  }, [aggregatorData, id]);

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES);

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        if (response.data) {
          checkHeadPri(response.data);
        }
        console.log(response?.data);
        console.log(privilagesData);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAggregatorData = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_AGGREGATOR + id);
      if (response.statusCode === 200) {
        setAggregatorData(response?.data[0]);
      } else {
        setAggregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPrivilagesbyID = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES_BY_ID + id);
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          return item.adminPrivilegeSubTypeId + "";
        });

        setPrivilages(res);
      } else {
        setPrivilages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      value = value.toUpperCase();
      if (name === "MobileNumber") {
        // Remove any non-numeric characters
        value = value.replace(/\D/g, "");
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    const head = e.target.dataset.head + "";
    console.log(head);

    // setHeadPrivilages((prevPrivilages) => {
    //   if (!e.target.checked) {
    //     // If any sub-privilege is unchecked, remove the head privilege
    //     return prevPrivilages.filter((id) => id !== head);
    //   }
    //   return prevPrivilages;
    // });

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = e.target.name;
    console.log(e.target.checked);

    const subtypeArray = privilagesData.find((item) => {
      return String(item.adminPrivilegeId) === adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.adminPrivilegeSubTypeId + "";
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAggregatorData();
        await getAllPrivilagesbyID();
        await getAllPrivilages();
      } catch (error) {}
    };

    fetchData();
  }, [id]);

  const checkHeadPri = (privilagesData) => {
    const updatedHeadPrivilages = [];
    console.log(privilages);
    privilagesData?.forEach((item1, index) => {
      const kjhfd = item1.subTypes.map((item) =>
        privilages.includes(item.adminPrivilegeSubTypeId + "")
      );
      console.log(kjhfd.includes(false), `${index}`);
      if (!kjhfd.includes(false)) {
        // updatedHeadPrivilages.push(item1.adminPrivilegeId+'');
        setHeadPrivilages((prev) => {
          return [...prev, item1.adminPrivilegeId + ""];
        });
      }
    });
  };

  useEffect(() => {
    console.log(HeadPrivilages);
  }, [HeadPrivilages]);

  const handleSubmit = async (e) => {
    if (formDataInputs.AggregatorName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Aggregator Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.Prifix === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Prifix",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter password",
        icon: "error",
      });
      return;
    }
    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }
    if (privilages.length === 0) {
      swal({
        title: "Alert!",
        text: "Please select at least one privilege",
        icon: "error",
      });
      return;
    }
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });
    const payload = {
      name: formDataInputs.AggregatorName.trim(),
      masterMerchantId: formDataInputs.masterMerchantName,
      password: formDataInputs.password.trim(),
      mobile: formDataInputs.MobileNumber.trim(),
      midprifix: formDataInputs.Prifix.trim(),
      email: formDataInputs.emailId.trim(),
      adminPrivilege: privilegs,
    };

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      const res = await apiCallFnforPut(AGGREGATOR_UPDATE, payload);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "Aggregator Updated Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          AggregatorName: "",
          password: "",
          MobileNumber: "",
          Prifix: "",
          emailId: "",
        });
        setPrivilages([]);
        setPrivilagesData([]);
        getAllPrivilages();
        onHide();
        setRefresh(!refresh);
        setCurrentId();
        // handleClose();
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };
  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };

  useEffect(() => {
    checkHeadPri(privilagesData);
  }, [privilagesData]);


  const handleClose = () => {
    setFormDataInputs({
      AggregatorName: "",
      password: "",
      MobileNumber: "",
      Prifix: "",
      emailId: "",
    });
    setPrivilages([]);
    setPrivilagesData([]);
    getAllPrivilages();
    onHide();
    setRefresh(!refresh);
    setCurrentId();
  }

  return (
    <>
      {/* new */}

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
        backdrop="static" 
        keyboard={false}
      >
        <div
          className="d-flex justify-content-start align-items-center mt-3"
          style={{ marginLeft: "28px" }}
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5
            class="offcanvas-title fs-2 text-dark fw-bold"
            id="offcanvasLabel"
          >
            Edit Master Merchant
          </h5>
        </div>

        <Offcanvas.Body>
          {/* new end */}

          {/* <div class="content-body"> */}
          {/* <div class="container-fluid"> */}
          {/* <div class="row page-titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">Edit Master Merchant</a>
              </li>
            </ol>
          </div> */}
          <div class="row">
            <div class="col-12">
              {/* <div className="card input-box-shadow"> */}
              <div className="card-body py-4">
                <div id="" className="form-wizard order-create">
                  <div id="" className="tab-pane" role="tabpanel">
                    <div className="row">
                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Master Merchant Name
                            <sup className="text-danger">*</sup>
                          </label>

                          <input
                            className="form-control"
                            name="AggregatorName"
                            value={formDataInputs.AggregatorName}
                            onChange={handleformDataInputs}
                          />
                        </div>
                      </div>
                      {/* ./form-group */}

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>

                          <input
                            type="email"
                            className="form-control"
                            name="emailId"
                            value={formDataInputs.emailId}
                            onChange={handleformDataInputs}
                            onKeyDown={(e) =>
                              setPressedKeyName(getKeyPressed(e))
                            }
                            onBlur={handleValidations}
                          />
                        </div>
                      </div>
                      {/* ./form-group */}

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Merchant Mobile Number
                            <sup className="text-danger">*</sup>{" "}
                          </label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name="MobileNumber"
                            value={formDataInputs.MobileNumber}
                            onChange={handleformDataInputs}
                            required
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Prifix
                            <sup className="text-danger">*</sup>
                          </label>

                          <input
                            className="form-control"
                            name="Prifix"
                            value={formDataInputs.Prifix}
                            onChange={handleformDataInputs}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <div className="mb-3">
                          <label className="mb-1 text-dark fs-4">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>

                          <input
                            className="form-control"
                            name="password"
                            value={formDataInputs.password}
                            onChange={handleformDataInputs}
                          />
                        </div>
                      </div>
                      {/* ./form-group */}

                      <div
                        className="pt-4"
                        style={{ borderTop: "0.2px solid gray" }}
                      >
                        <h4 className="fs-2">Privilages</h4>
                      </div>
                      <Row className="mt-3">
                        <div className="d-flex flex-column gap-4">
                          {privilagesData &&
                            privilagesData.map((itemMain, i) => (
                              <div key={i} className="d-flex flex-column">
                                {/* Start */}
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                  <div class="checkbox-wrapper-31">
                                    <input
                                      id="flexSwitchCheckDefault"
                                      type="checkbox"
                                      name={itemMain.adminPrivilegeId}
                                      checked={HeadPrivilages.includes(
                                        itemMain.adminPrivilegeId + ""
                                      )}
                                      onChange={handleHeadPrivilages}
                                    />
                                    <svg viewBox="0 0 35.6 35.6">
                                      <circle
                                        class="background"
                                        cx="17.8"
                                        cy="17.8"
                                        r="17.8"
                                      ></circle>
                                      <circle
                                        class="stroke"
                                        cx="17.8"
                                        cy="17.8"
                                        r="14.37"
                                      ></circle>
                                      <polyline
                                        class="check"
                                        points="11.78 18.12 15.55 22.23 25.17 12.87"
                                      ></polyline>
                                    </svg>
                                  </div>
                                  <h1 className="pt-2">
                                    {itemMain.privilegeName}
                                  </h1>
                                </div>
                                <div className="row fs-4 mt-2 text-dark">
                                  {itemMain.subTypes.map((item, i) => (
                                    <div
                                      key={i}
                                      class="checkbox-wrapper-27 col-lg-4 col-md-6 col-sm-12 d-flex "
                                    >
                                      {/* Start */}
                                      <label class="checkbox">
                                        <input
                                          id="flexSwitchCheckDefault"
                                          data-head={itemMain.adminPrivilegeId}
                                          type="checkbox"
                                          name={item.adminPrivilegeSubTypeId}
                                          checked={privilages.includes(
                                            item.adminPrivilegeSubTypeId + ""
                                          )}
                                          onChange={handlePrivilages}
                                        />
                                        <span class="checkbox__icon"></span>
                                        {item.subTypePrivilegeName}
                                      </label>
                                      {/* End */}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ))}
                        </div>
                      </Row>

                      <div className="d-flex justify-content-center mt-4">
                        <button
                          className="badge badge-rounded badge-primary mx-2 px-5 py-3"
                          onClick={handleSubmit}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MasterMerchantEdit;
