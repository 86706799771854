import React, { useEffect, useState } from "react";
import RemoveIcon from '@mui/icons-material/Remove';
import {
  FETCH_PAYIN_FOR_ACKNOWLEDGMENT,
  GET_ALL_AGGREGATOR,
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
} from "../../utils/constant";
import { useLocation } from "react-router-dom";
import {
  getAllFunctionForApiCall,
  getAllFunctionForApiCalls,
} from "../../utils/ApiServices";
import { Button, Card, Col, Row, Offcanvas} from "react-bootstrap";
import { setPrivilagesData } from "../../store/slices/privilagesSlice";
import useApiServices from "../../utils/useApiServices";

const offcanvasStyle = {
  width: '90vw', // Set width to 70vw
};


const ViewSingleSubmittedAggregator = ({id, show, onHide,refresh}) => {
  // console.log("currentIdINVIEW-------->",id)
  const [loading, setLoading] = useState(false);
  const [aggregatorData, setAggregatorData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const token = localStorage.getItem("token");

  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    masterMerchantName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });
const {apiCallFnforGet} = useApiServices();
  const location = useLocation();

// const AggregatorId = id;

  useEffect(() => {
    setFormDataInputs({
      AggregatorName: aggregatorData.name,
      masterMerchantName: aggregatorData.masterMerchantId,
      password: aggregatorData.password,
      MobileNumber: aggregatorData.mobile,
      Prifix: aggregatorData.midprifix,
      emailId: aggregatorData.email,
    });
  }, [aggregatorData, id]);

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_PRIVILAGES,
         );

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        if (response.data) {
          checkHeadPri(response.data);
        }
        console.log(response?.data);
        console.log(privilagesData);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAggregatorData = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_AGGREGATOR + id,
        
      );
      if (response.statusCode === 200) {
        setAggregatorData(response?.data[0]);
      } else {
        setAggregatorData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPrivilagesbyID = async () => {
    try {
      const response = await apiCallFnforGet(
        GET_ALL_PRIVILAGES_BY_ID + id,
        
      );
      if (response.statusCode === 200) {
        const res = response?.data?.map((item) => {
          return item.adminPrivilegeSubTypeId + "";
        });

        setPrivilages(res);
      } else {
        setPrivilages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await getAggregatorData();
        await getAllPrivilagesbyID();
        await getAllPrivilages();


        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id,refresh]);

  const checkHeadPri = (privilagesData) => {
    const updatedHeadPrivilages = [];
    console.log(privilages);
    privilagesData?.forEach((item1, index) => {
      const kjhfd = item1.subTypes.map((item) =>
        privilages.includes(item.adminPrivilegeSubTypeId + "")
      );

      if (!kjhfd.includes(false)) {
        console.log("yesINSIDE");
        // updatedHeadPrivilages.push(item1.adminPrivilegeId+'');
        setHeadPrivilages((prev) => {
          return [...prev, item1.adminPrivilegeId + ""];
        });
      }
    });
  };
  useEffect(() => {
    checkHeadPri(privilagesData);
  }, [privilagesData]);

  return (
    <>
    {/* New */}

    <Offcanvas show={show} onHide={onHide} backdrop="static" keyboard={false} placement="end" style={offcanvasStyle}>
    <div className="d-flex justify-content-start align-items-center mt-3" style={{marginLeft:"28px"}}>

<Offcanvas.Header closeButton >
</Offcanvas.Header>
<h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">View Master Merchant</h5>
    </div>
   
    
    
    <Offcanvas.Body >
    {/* New-2 */}
      {/* <div class="content-body"> */}
        {/* <div class="container-fluid"> */}
          {/* <div class="row page-titles">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="javascript:void(0)">View Submitted Master Merchant</a>
              </li>
            </ol>
          </div> */}
          {/* <div class="row"> */}
            {/* <div class="col-12"> */}
              {/* <div className="card input-box-shadow"> */}
                <div className="card-body py-4">
                  <div id="" className="form-wizard order-create">
                    <div id="" className="tab-pane" role="tabpanel">
                      <div className="row">
                      <div className="table-responsive">
                <div className="table table-bordered">
                <div className="row">
                  {[
                    {
                      label: "Master Merchant Name",
                      value: formDataInputs.AggregatorName,
                    },
                    {
                      label: "E-mail",
                      value: formDataInputs.emailId,
                    },
                    {
                      label: "Master Mobile Number",
                      value: formDataInputs.MobileNumber,
                    },
                    {
                      label: "Prifix",
                      value: formDataInputs.Prifix,
                    },
                    {
                      label: "Password",
                      value: formDataInputs.password,
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="col-6 border-bottom border-dark "
                      // style={{ borderRight: "0.5px solid black", borderLeft: "0.5px solid black", paddingBottom:"0px"}}
                    >
                      <div className="d-flex justify-content-between align-items-center table table-bordered">
                     
                        <tr className="fw-bold mt-2 w-100 d-flex justify-content-between align-items-center">{item.label}   <RemoveIcon /></tr>{" "}
    
                        <tr className="mt-2 w-100 text-end">{item.value}</tr>
                      </div>
                    </div>
                  ))}
                </div>
                </div>
                </div>

                        <div className="pt-4" >
                          <h4 className="fs-2">Privilages</h4>
                        </div>
                        <Row className="mt-3">
                          <div className="d-flex flex-column gap-4">
                            {privilagesData &&
                              privilagesData.map((itemMain, i) => (
                                <div key={i} className="d-flex flex-column">
                                   <div className="d-flex justify-content-start align-items-center gap-2">
                                    
                                    <div class="checkbox-wrapper-31">
                                      <input
                                       id="flexSwitchCheckDefault"
                                         type="checkbox"
                                         readOnly
                                         name={itemMain.adminPrivilegeId}
                                         checked={HeadPrivilages.includes(
                                           itemMain.adminPrivilegeId + ""
                                         )}
                                      />
                                      <svg viewBox="0 0 35.6 35.6">
                                        <circle
                                          class="background"
                                          cx="17.8"
                                          cy="17.8"
                                          r="17.8"
                                        ></circle>
                                        <circle
                                          class="stroke"
                                          cx="17.8"
                                          cy="17.8"
                                          r="14.37"
                                        ></circle>
                                        <polyline
                                          class="check"
                                          points="11.78 18.12 15.55 22.23 25.17 12.87"
                                        ></polyline>
                                      </svg>
                                    </div>
                                    <h1 className="pt-2">
                                      {itemMain.privilegeName}
                                    </h1>
                                  </div>
                                 

                                  <div className="row fs-4 mt-2 text-dark">
                                    {itemMain.subTypes.map((item, i) => (
                                      <div
                                      key={i}
                                      class="checkbox-wrapper-27 col-lg-4 col-md-6 col-sm-12 d-flex "
                                    >
                                      {/* Start */}
                                      <label class="checkbox">
                                            <input
                                              id="flexSwitchCheckDefault"
                                              data-head={
                                                itemMain.adminPrivilegeId
                                              }
                                              type="checkbox"
                                              readOnly
                                              name={item.adminPrivilegeSubTypeId}
                                              checked={privilages.includes(
                                                item.adminPrivilegeSubTypeId + ""
                                              )}
                                            />
                                            <span class="checkbox__icon"></span>
                                            {item.subTypePrivilegeName}
                                          </label>
                                      {/* End */}
                                      
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </div> */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
      {/* </div> */}

      </Offcanvas.Body>
  </Offcanvas>
    </>
  );
};
export default ViewSingleSubmittedAggregator;





    