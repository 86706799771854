import React, { useState, useEffect } from "react";
import { Card, Col, Row, Offcanvas, Button, Nav } from "react-bootstrap";
import useApiServices from "../../utils/useApiServices";
import {
  GET_ALL_PRIVILAGES, AGGREGATOR_SAVE,
  GET_ALL_VEHICLE_TYPE_WITH_ACTIVE,
  SAVE_VEHICLE_MASTER_FORM,
  SETTLEMENT_TYPE_GET,
  MASTER_MERCHANT_SERVICES_POST,
} from "../../utils/constant";
import DropDowns from "./DropDowns";
import swal from "sweetalert";
// import { useDispatch } from "react-redux";

import { setAllPrivilagesData } from "../../store/slices/privilagesSlice";
import { isEmailIdRegexIsValid, getKeyPressed, isMobileNumberValid } from "../../utils/helper";
import MyLoader from "../../common/MyLoader";
import { useDispatch } from "react-redux";
import { Refresh } from "@mui/icons-material";


const offcanvasStyle = {
  width: '90vw',
};

const CreateMasterMerchant = ({ show, onHide, setRefresh, refresh, setShow2 }) => {

  const [showLoader, setShowLoader] = useState(false);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [step, setStep] = useState(1);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });

  const [savedMerchantName, setSavedMerchantName] = useState('');
  const [settlementData, setSettlementData] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);
  const [serviceDetails, setServiceDetails] = useState({});
  const [selectedType, setSelectedType] = useState({
    schemeId: "",
    aggeregatorId: "",
    masterMerchantId: "",
    switchId: "",
    vehicleName: "",
    bankId: "",
    schemeName: "",
    aggeregatorName: "",
    switchName: "",
    bankName: "",
    remark: "",
  });

  // This is step 1 code

  const getAllPrivilages = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_PRIVILAGES);

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        dispatch(setAllPrivilagesData(response?.data));
        console.log(response.data);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  function setNumericInput(input) {
    const numericRegex = /^[0-9]+$/;
    return numericRegex.test(input) ? input : "";
  }
  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "password" || name === "emailId") {
      setFormDataInputs({ ...formDataInputs, [name]: value });
    } else {
      if (name === "Prifix") {
        value = value
          .slice(0, 2)
          .toUpperCase()
          .replace(/[^A-Z]/g, "");
      } else if (name === "MobileNumber") {
        value = value.replace(/\D/g, "");
      } else {
        value = value.toUpperCase();
      }
      setFormDataInputs({ ...formDataInputs, [name]: value });
    }
  };
  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    const head = e.target.dataset.head + "";
    console.log(head);

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = e.target.name;
    console.log(e.target.checked);

    const subtypeArray = privilagesData?.find((item) => {
      return String(item.adminPrivilegeId) === adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.adminPrivilegeSubTypeId + "";
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev?.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };
  useEffect(() => {
    console.log(privilages);
  }, [privilages]);

  function convertCamelCaseToSentence(inputString) {
    const spacedString = inputString
      .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
      .replace(/([a-zA-Z])([0-9])/g, "$1 $2")
      .replace(/([A-Z])(?=[A-Z])/g, "$1 ");

    // Capitalize the first letter of the result
    const capitalizedString =
      spacedString.charAt(0).toUpperCase() + spacedString.slice(1);

    return capitalizedString;
  }

  const handleSubmit = async (e) => {
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });

    const payload = {
      name: formDataInputs.AggregatorName.trim(),
      password: formDataInputs.password.trim(),
      mobile: formDataInputs.MobileNumber.trim(),
      midprifix: formDataInputs.Prifix.trim(),
      email: formDataInputs.emailId.trim(),
      adminPrivilege: privilegs,
    };

    if (formDataInputs.AggregatorName === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Merchant Name",
        icon: "error",
      });
      return;
    }

    if (!isMobileNumberValid(formDataInputs.MobileNumber)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Mobile Number",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs, MobileNumber: "" });
      });
      return;
    }
    if (formDataInputs.Prifix === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Prifix",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter password",
        icon: "error",
      });
      return;
    }

    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }

    if (privilages.length === 0) {
      swal({
        title: "Alert!",
        text: "Please select at least one privilege",
        icon: "error",
      });
      return;
    }
    console.log(payload);

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      setShowLoader(true);
      const res = await apiCallFnforPost(AGGREGATOR_SAVE, payload);
      setShowLoader(false);

      if (res.statusCode === 200) {
        const merchantName = res?.duplicateData || formDataInputs.AggregatorName;
        setSavedMerchantName(merchantName);
        setStep(2);

      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };

  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };
  const handleClose = () => {
    setFormDataInputs({
      AggregatorName: "",
      password: "",
      MobileNumber: "",
      Prifix: "",
      emailId: "",
    });
    setPrivilages([]);
    setPrivilagesData([]);
    getAllPrivilages();

    setSelectedType({
      vehicleName: "",
      schemeId: "",
      bankId: "",
      switchId: "",
      masterMerchantId: "",
      aggeregatorId: "",
      remark: "",
    });
    setSelectedPaymentSubTypes([]);
    setServiceDetails({});
    setSavedMerchantName("");

    onHide();
    setRefresh(!refresh);
  };

  // This is step 2 code

  useEffect(() => {
    GetAllSettlementData();
    GetVehicleType();
  }, []);

  const GetAllSettlementData = async () => {
    try {
      const response = await apiCallFnforGet(SETTLEMENT_TYPE_GET);
      if (response.statusCode === 200) {
        setSettlementData(response?.data);
      } else {
        setSettlementData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleType = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICLE_TYPE_WITH_ACTIVE);
      if (response.statusCode === 200) {
        setPaymentTypes(response?.data);
      } else {
        setPaymentTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTypeChange = (event) => {
    const selectedId = event.target.value;
    setSelectedType((prev) => ({ ...prev, settlementId: selectedId }));
  };

  const handleCheckboxChange = (paymentSubService) => {
    setSelectedPaymentSubTypes((prev) =>
      prev.includes(paymentSubService)
        ? prev.filter((item) => item !== paymentSubService)
        : [...prev, paymentSubService]
    );

    setServiceDetails((prev) => {
      const newDetails = { ...prev };
      if (newDetails[paymentSubService]) {
        delete newDetails[paymentSubService];
      }
      return newDetails;
    });
  };

  const handleInputChange = (paymentSubService, field, value) => {

    const numericValue = parseFloat(value);
    const clampedValue = isNaN(numericValue) ? 0 : Math.max(0, Math.min(numericValue, 100));


    setServiceDetails((prev) => ({
      ...prev,
      [paymentSubService]: {
        ...prev[paymentSubService],
        [field]: value,
      },
    }));
  };

  const handleSubmit1 = async () => {
    const services = selectedPaymentSubTypes.map((service) => ({
      serviceId: paymentTypes
        ?.find(paymentType => paymentType.paymentSubTypedtos
          ?.find(type => type.paymentSubService === service)
        )
        ?.paymentSubTypedtos
        ?.find(type => type.paymentSubService === service)
        ?.paymentSubTypeId,
      fixAmount: serviceDetails[service]?.fixedAmount || 0,
      percentage: serviceDetails[service]?.percentage || 0,
      mdrValue: serviceDetails[service]?.mdr || 0,
    }));
  
    const hasInvalidFixedAmount = services.some((item) => {
      const fixAmount = parseFloat(item.fixAmount);
      return isNaN(fixAmount) || fixAmount <= 0;
    });
  
    const hasInvalidPercentage = services.some((item) => {
      const percentage = parseFloat(item.percentage);
      return isNaN(percentage) || percentage < 0 || percentage > 100;
    });
  
    const hasInvalidMDR = services.some((item) => {
      const mdrValue = parseFloat(item.mdrValue);
      return isNaN(mdrValue) || mdrValue < 0 || mdrValue > 100;
    });
  
    if (hasInvalidFixedAmount || hasInvalidPercentage || hasInvalidMDR) {
      swal({
        title: "Alert!",
        text: "Please ensure Fixed Amount is not negative, and Percentage and MDR are within the range of 0 to 100.",
        icon: "error",
      });
      return;
    }
  
    const selectedSettlement = settlementData.find(
      (settlement) => settlement.name === selectedType.settlementId
    );
  
    if (!selectedSettlement) {
      swal({
        title: "Alert!",
        text: "Selected settlement type is not valid.",
        icon: "error",
      });
      return;
    }
  
    const payload = {
      services,
      settlementTypeId: selectedSettlement.id,
      masterMerchantId: savedMerchantName,
    };
  
    try {
      const response = await apiCallFnforPost(MASTER_MERCHANT_SERVICES_POST, payload);
      if (response.statusCode === 200) {
        swal("Success!", "Data saved successfully.", "success");
        handleClose();
      } else {
        swal("Error!", "Something went wrong.", "error");
      }
    } catch (error) {
      console.log(error);
      swal("Error!", "An unexpected error occurred.", "error");
    }
  };
  

  return (
    <>
      {showLoader ? <MyLoader /> : ""}

      {/* new */}

      <Offcanvas show={show} onHide={handleClose} backdrop="static" keyboard={false} placement="end" style={offcanvasStyle}>

        <div className="d-flex justify-content-start align-items-center mt-3" style={{ marginLeft: "28px" }}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 class="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            Create Master Merchant
          </h5>
        </div>

        <Offcanvas.Body >
          <div className="wizard-container">
            <Nav variant="tabs" className="wizard-nav">
              <Nav.Item>
                <Nav.Link active={step === 1} onClick={() => setStep(1)}>Step 1</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link active={step === 2} onClick={() => setStep(2)}>Step 2</Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="wizard-content">
              {step === 1 && (
                <div class="row">
                  <div class="col-12">
                    <div className="card-header">
                      <h4 className="card-title">Fill Master Merchant Details</h4>
                    </div>
                    <div className="card-body py-4">
                      <div id="" className="form-wizard order-create">
                        <div id="" className="tab-pane" role="tabpanel">
                          <form className="form-section-form-group">
                            <div className="row">

                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <label className="mb-1 text-dark fs-4">
                                    Master Merchant Name
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    name="AggregatorName"
                                    value={formDataInputs.AggregatorName}
                                    onChange={handleformDataInputs}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <label className="mb-1 text-dark fs-4">
                                    e-Mail Id
                                  </label>

                                  <input
                                    type="email"
                                    className="form-control"
                                    name="emailId"
                                    value={formDataInputs.emailId}
                                    onChange={handleformDataInputs}
                                    onKeyDown={(e) =>
                                      setPressedKeyName(getKeyPressed(e))
                                    }
                                    onBlur={handleValidations}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <label className="mb-1 text-dark fs-4">
                                    {" "}
                                    Merchant Mobile Number
                                  </label>

                                  <input
                                    className="form-control"
                                    maxLength={10}
                                    minLength={10}
                                    name="MobileNumber"
                                    value={formDataInputs.MobileNumber}
                                    onChange={handleformDataInputs}
                                    required
                                    pattern="[0-9]{10}"
                                    title="Please enter a 10-digit mobile number"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <label className="mb-1 text-dark fs-4"> Prefix</label>

                                  <input
                                    className="form-control"
                                    name="Prifix"
                                    value={formDataInputs.Prifix}
                                    onChange={handleformDataInputs}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <label className="mb-1 text-dark fs-4">Password</label>

                                  <input
                                    className="form-control"
                                    name="password"
                                    value={formDataInputs.password}
                                    onChange={handleformDataInputs}
                                  />
                                </div>
                              </div>

                              <div className="card-header">
                                <h4 className="card-title">Privilege</h4>
                              </div>
                              <Row className="mt-3">

                                <div className="d-flex flex-column gap-4">
                                  {privilagesData &&
                                    privilagesData.map((itemMain, i) => (

                                      <div key={i} className="d-flex flex-column">
                                        <div className="d-flex justify-content-start align-items-center gap-2">

                                          <div class="checkbox-wrapper-31">
                                            <input
                                              type="checkbox"
                                              name={itemMain.adminPrivilegeId}
                                              value={HeadPrivilages.includes(
                                                itemMain.adminPrivilegeId + ""
                                              )}
                                              onChange={handleHeadPrivilages}
                                            />
                                            <svg viewBox="0 0 35.6 35.6">
                                              <circle
                                                class="background"
                                                cx="17.8"
                                                cy="17.8"
                                                r="17.8"
                                              ></circle>
                                              <circle
                                                class="stroke"
                                                cx="17.8"
                                                cy="17.8"
                                                r="14.37"
                                              ></circle>
                                              <polyline
                                                class="check"
                                                points="11.78 18.12 15.55 22.23 25.17 12.87"
                                              ></polyline>
                                            </svg>
                                          </div>
                                          <h1 className="pt-2">
                                            {itemMain.privilegeName}
                                          </h1>
                                        </div>
                                        {/* </Card.Header> */}
                                        <div className="row fs-4 mt-2 text-dark">
                                          {itemMain.subTypes.map((item, i) => (
                                            <div
                                              key={i}
                                              class="checkbox-wrapper-27 col-lg-4 col-md-6 col-sm-12 d-flex "
                                            >
                                              <label class="checkbox">
                                                <input
                                                  id="flexSwitchCheckDefault"
                                                  type="checkbox"
                                                  data-head={
                                                    itemMain.adminPrivilegeId
                                                  }
                                                  name={
                                                    item.adminPrivilegeSubTypeId
                                                  }
                                                  checked={privilages.includes(
                                                    item.adminPrivilegeSubTypeId +
                                                    ""
                                                  )}
                                                  onChange={handlePrivilages}
                                                />
                                                <span class="checkbox__icon"></span>
                                                {item.subTypePrivilegeName}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </Row>
                              <div className="d-flex justify-content-end mt-4">
                                <Button variant="primary" onClick={handleSubmit}>Next</Button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="">
                      <div className="card-body py-4">
                        <div id="" className="form-wizard order-create">
                          <div id="" className="tab-pane" role="tabpanel">
                            <div className="row">
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  <label className="mb-1 text-dark fs-4">
                                    Master Merchant Name
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control"
                                    name="merchantname"
                                    disabled
                                    value={formDataInputs.AggregatorName}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-2">
                                <div className="mb-3">
                                  {settlementData.length > 0 ? (
                                    <DropDowns
                                      fieldName={"Settlement Type"}
                                      Name={"settlementId"}
                                      HandleChange={handleTypeChange}
                                      SelectedType={selectedType.settlementId}
                                      AllTypes={settlementData}
                                    />
                                  ) : (
                                    <DropDowns
                                      fieldName={"Settlement Type"}
                                      Name={"settlementId"}
                                      HandleChange={() => { }}
                                      SelectedType=""
                                      AllTypes={[]}
                                    />
                                  )}
                                </div>
                              </div>

                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Select</th>
                                    <th>Service Type</th>
                                    <th>Sub-service Type</th>
                                    <th>Fixed Amount (monthly)</th>
                                    <th>Percentage (monthly)</th>
                                    <th>MDR (percentage)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {paymentTypes?.map((paymentType, i) =>
                                    paymentType?.paymentSubTypedtos?.map((paymentSubType, j) => (
                                      <tr key={`${i}-${j}`}>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="typeOfServices"
                                            value={paymentSubType.paymentSubService}
                                            checked={selectedPaymentSubTypes.includes(
                                              paymentSubType.paymentSubService
                                            )}
                                            onChange={() => handleCheckboxChange(paymentSubType.paymentSubService)}
                                          />
                                        </td>
                                        <td className="fw-bolder">{paymentType.paymentService.toUpperCase()}</td>
                                        <td>{paymentSubType.paymentSubService}</td>
                                        <td>
                                          <input
                                            name="fixedAmount"
                                            type="number"
                                            placeholder="Fixed Amount"
                                            className="form-control"
                                            value={serviceDetails[paymentSubType.paymentSubService]?.fixedAmount || ''}
                                            onChange={(e) =>
                                              handleInputChange(paymentSubType.paymentSubService, 'fixedAmount', e.target.value)
                                            }
                                            disabled={!selectedPaymentSubTypes.includes(paymentSubType.paymentSubService)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            name="Percentage"
                                            type="number"
                                            placeholder="0"
                                            className="form-control"
                                            value={serviceDetails[paymentSubType.paymentSubService]?.percentage || ''}
                                            onChange={(e) =>
                                              handleInputChange(paymentSubType.paymentSubService, 'percentage', e.target.value)
                                            }
                                            disabled={!selectedPaymentSubTypes.includes(paymentSubType.paymentSubService)}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            name="mdr"
                                            type="number"
                                            placeholder="0"
                                            className="form-control"
                                            value={serviceDetails[paymentSubType.paymentSubService]?.mdr || ''}
                                            onChange={(e) =>
                                              handleInputChange(paymentSubType.paymentSubService, 'mdr', e.target.value)
                                            }
                                            disabled={!selectedPaymentSubTypes.includes(paymentSubType.paymentSubService)}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>

                              <div className="d-flex justify-content-end">
                                <button
                                  className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                                  onClick={handleSubmit1}
                                >
                                  Submit
                                </button>
                                {/* <Button variant="primary" onClick={() => setStep(3)}>Next</Button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CreateMasterMerchant;