import React, { useEffect, useState } from "react";
import { reporticons } from "../../Assets/images/image";
import { toolIcons } from "../../Assets/images/image";
import styles from "./report.module.css";
import LivePayinReport from "./LivePayinReport";
import Chargeback from "./Chargeback";
import EKyc from "./EKyc";
import Withdraw from "./Withdraw";
import Payout from "./Payout";
import SlideBox from "../../common/SlideBox/SlideBox";
import Ledger from "./Ledger";
import DownloadCSV from "./DownloadCSV";
import { FETCH_ALL_MERCHANT_VALIDATIONS } from "../../utils/constant";
import useApiServices from "../../utils/useApiServices";
import MerchantTransaction from "./MerchantTransaction";
import Reconciliation from "./Reconciliation";
import useCheckPrivilages from "../../utils/checkPrivilages";
import WalletBalance from "./WalletBalance";
import Settlement from "./Settlement";
import Vendors from "./Vendors";
import Release from "./Release";
import CreditBalance from "./CreditBalance";
import ViewUTR from "./ViewUTR";
import Guarantee from "./Guarantee";

const Report = () => {
  const [flag, setFlag] = useState(false);
  const [report, setReport] = useState("");
  const [dropDownData, setDropDownData] = useState([]);
  const { apiCallFnforGet } = useApiServices();
  const hasPrivilages2713 = useCheckPrivilages(2713);
  const hasPrivilages2714 = useCheckPrivilages(2714);

  const reportType = {
    Payin: "Payin",
    Payout: "Payout",
    Chargeback: "Chargeback",
    Withdraw: "Withdraw",
    Ekyc: "Ekyc",
    Archived: "Archived",
    Ledger: "Ledger",
    MerchantTransaction: "MerchantTransaction",
    reconciliation: "reconciliation",
    Wallet: "Wallet",
    Settlement: "Settlement",
    Vendors:"Vendors",
    Release:"Release",
    CreditBalance:"CreditBalance",
   ViewUTR : "ViewUTR",
   Guarantee:"Guarantee",
  };

  const toggleRightBar = (type) => {
    if (type) {
      setReport(type);
    }

    setFlag(!flag);
  };

  const getMerchantData = async (token) => {
    try {
      // setShowLoader(true)
      const response = await apiCallFnforGet(FETCH_ALL_MERCHANT_VALIDATIONS);
      if (response?.statusCode === 200) {
        setDropDownData(response?.data);
        // setShowLoader(false)
        console.log(response?.data);
      } else {
        setDropDownData([]);
        // setShowLoader(false)
      }
    } catch (error) {
      setDropDownData([]);
      // setShowLoader(false)
    }
  };

  useEffect(() => {
    getMerchantData();
  }, []);
  return (
    <div className="content-body">
      <div className="container-fluid ">
        <div class="card card-inverse card-primary text-center">
          <div class="card-body pb-1">
            <SlideBox flag={flag} toggleRightBar={toggleRightBar}>
              {reportType[report] === "Payin" && (
                <LivePayinReport
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "Chargeback" && (
                <Chargeback
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "Withdraw" && (
                <Withdraw
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "Payout" && (
                <Payout
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "reconciliation" && (
                <Reconciliation
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "Wallet" && (
                <WalletBalance
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
               {reportType[report] === "Settlement" && (
                <Settlement
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
               {reportType[report] === "Release" && (
                <Release
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
               {reportType[report] === "CreditBalance" && (
                <CreditBalance
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
               {reportType[report] === "Vendors" && (
                <Vendors
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "Ekyc" && (
                <EKyc type={report} toggleRightBar={toggleRightBar} />
              )}
              {reportType[report] === "Archived" && (
                <DownloadCSV type={report} toggleRightBar={toggleRightBar} />
              )}
              {reportType[report] === "Ledger" && (
                <Ledger type={report} toggleRightBar={toggleRightBar} />
              )}
              {reportType[report] === "MerchantTransaction" && (
                <MerchantTransaction
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
              {reportType[report] === "ViewUTR" && (
                <ViewUTR
                  type={report}
                  toggleRightBar={toggleRightBar}
                />
              )}
                 {reportType[report] === "Guarantee" && (
                <Guarantee
                  type={report}
                  dropDownData={dropDownData}
                  toggleRightBar={toggleRightBar}
                />
              )}
            </SlideBox>

            <div class="row text-center text-lg-start">
              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center"
                    onClick={() => toggleRightBar("Payin")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Payin}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">Payin</h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5   ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Chargeback")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.ChargeBack}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Chargeback
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5  ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Withdraw")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Withdraw}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Withdraw
                    </h5>
                  </div>
                </div>
              ) : null}

              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Payout")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Payout}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Payout
                    </h5>
                  </div>
                </div>
              ) : null}
              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("reconciliation")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.reconciliationTools}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Reconciliation
                    </h5>
                  </div>
                </div>
              ) : null}
                 {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Wallet")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.Wallet}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Wallet Balance{" "}
                    </h5>
                  </div>
                </div>
              ) : null} 
              

               {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Settlement")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Settlement}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Settlement{" "}
                    </h5>
                  </div>
                </div>
              ) : null} 
               {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Vendors")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={toolIcons.RegisterVendor}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Vendors{" "}
                    </h5>
                  </div>
                </div>
              ) : null} 
              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5  ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Release")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.Ekyc}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">Release</h5>
                  </div>
                </div>
              ) : null}
              {hasPrivilages2714 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("CreditBalance")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.CreditBalance}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Credit Balance{" "}
                    </h5>
                  </div>
                </div>
              ) : null}

              <div
                className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("Archived")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={reporticons.Archived}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">
                    Archived Reports
                  </h5>
                </div>
              </div>
              
              {/* <div
                className={col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}}
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center "
                  onClick={() => toggleRightBar("Ledger")}
                >
                  <img
                    class="img-fluid img-thumbnail"
                    src={reporticons.Ledger}
                    alt=""
                  />
                  <h5 className="mt-3 text-primary text-md text-sm">Ledger</h5>
                </div>
              </div> */}
              <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("Guarantee")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.MerchantTransaction}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                    Guarantee{" "}
                    </h5>
                  </div>
                </div>
              {hasPrivilages2713 ? (
                <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("MerchantTransaction")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.MerchantTransaction}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                      Merchant Transaction{" "}
                    </h5>
                  </div>
                </div>
                
              ) : null}

              <div
                  className={`col-lg-3 col-md-3 col-4 mb-5 ${styles["report-icon"]}`}
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center "
                    onClick={() => toggleRightBar("ViewUTR")}
                  >
                    <img
                      class="img-fluid img-thumbnail"
                      src={reporticons.ViewUTR}
                      alt=""
                    />
                    <h5 className="mt-3 text-primary text-md text-sm">
                    View UTR{" "}
                    </h5>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;