import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ViewSingleSubmittedAggregator from "./ViewSingleSubmittedAggregator";
import ViewMasterMerchantPhase2 from "./ViewMasterMerchantPhase2";
import CreateMasterMerchant from "./CreateMasterMerchant";
import EditCreateMasterMerchantPhase3 from "./EditCreateMasterMerchantPhase2";
import EditCreateMasterMerchantPhase2 from "./EditCreateMasterMerchantPhase2";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CustomTip from "../../common/CustomToolTip/CustomTip";
import { MenuList, Paper, Popper } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  GET_ALL_AGGREGATOR,
  MASTER_MERCHANT_ENABLE_DISABLE,
} from "../../utils/constant";
import swal from "sweetalert";
import axios from "axios";
import useApiServices from "../../utils/useApiServices";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MasterMerchantEdit from "./MasterMerchantEdit";


const MMSubmitted = () => {
  const [currentid, setCurrentId] = useState(null);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showViewPhase2, setShowViewPhase2] = useState(false);
  const [showPhase2, setShowPhase2] = useState(false);
  const [showPhaseEdit, setShowPhaseEdit] = useState(false);
  const [submittedAggregator, setSubmittedAggregator] = useState([]);
  const [callAgain, setCallAgain] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const { apiCallFnforGet, apiCallFnforPut } = useApiServices();
  const [anchorEl, setAnchorEl] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const token = localStorage.getItem("token");
  const [masterMerchantX, setMasterMerchantX] = useState(null);

  const handleAction = (item, action) => {
    switch (action) {
      case "view":
        View(item.masterMerchantId);
        break;
      case "viewphase2":
        ViewPhase2(item.masterMerchantId, item);
        break;
      case "edit":
        EditView(item.masterMerchantId);
        break;
      case "editphase2":
        EditPhase2(item.masterMerchantId, item);
        break;
      case "enable":
        if (!item.active) {
          disableAggregator(item.masterMerchantId, "enable");
        }
        break;
      case "disable":
        if (item.active) {
          disableAggregator(item.masterMerchantId, "disable");
        }
        break;
      default:
        break;
    }
    setSelectedAction("")
  };
  const handleClickIcon = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuItemClick = (action) => {
    setSelectedAction(action);
    setAnchorEl(null); // Close the menu after selecting an option
  };

  const getAll = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_AGGREGATOR);
      if (response.statusCode === 200) {
        const finalRes = response?.data?.map((item, i) => ({
          id: i,
          masterMerchantId:item.masterMerchantId,
          name: item.name,
          midprifix: item.midprifix,
          email: item.email,
          mobile: item.mobile,
          action: (
            <Select
              value={selectedAction}
              onChange={(e) => {
                setSelectedAction(e.target.value)
                handleAction(item, e.target.value)
              }}
              displayEmpty
              renderValue={() => null}

              sx={{
                width: 40,
                backgroundColor: "#A3C8ED", // Adjust the color as per your preference
                borderRadius: 8, // Optional: Adds rounded corners for a nicer look
                padding: '8px 2px', // Optional: Adjust padding for better spacing
                height: '25px',
              }}
            >
              <MenuItem value="view">View Phase 1</MenuItem>
              <MenuItem value="viewphase2">View Phase 2</MenuItem>
              <MenuItem value="edit">Edit Phase 1</MenuItem>
              <MenuItem value="editphase2">Edit Phase 2</MenuItem>
              {item.active ? (
                <MenuItem value="disable" className="bg-danger text-white">Disable</MenuItem>
              ) : (
                <MenuItem value="enable" className="bg-success text-white">Enable</MenuItem>
              )}
            </Select>
          ),
        }));
        setSubmittedAggregator(finalRes);
      } else {
        setSubmittedAggregator([]);
      }
    } catch (error) {
      console.log(error);
    }
    setSelectedAction("")
  };
  
  useEffect(() => {
    getAll();
  }, [callAgain, refresh, setRefresh]);

  const disableAggregator = async (id, action) => {
    const EnableOrDisable = action === "enable" ? "enable" : "disable";

    swal({
      title: "Alert",
      text: `Are you sure, you want to ${EnableOrDisable}`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDisable) => {
      if (willDisable) {
        const formData = new FormData();
        formData.append("id", id);
        apiCallFnforPut(`${MASTER_MERCHANT_ENABLE_DISABLE}${action}/${id}`, "")
          .then((res) => {
            if (res.statusCode === 200) {
              swal({
                title: "Success",
                text: `Aggregator ${EnableOrDisable} Successfully.`,
                icon: "success",
              });
              setCallAgain((prevState) => !prevState);
            } else {
              swal({
                title: "Success",
                text: `Failed to ${EnableOrDisable} Aggregator.`,
                icon: "error",
              });
            }

          })
          .catch((error) => {
            console.error("Error fetching data:", error);

          });
      } else {

      }
    });
  };

  const View = (masterMerchantId) => {
    setShow(true);
    setCurrentId(masterMerchantId)
    setRefresh(!refresh)
  };

  const ViewPhase2 = (masterMerchantId, masterMerchant) => {
    setShowViewPhase2(true);
    setCurrentId(masterMerchantId)
    setRefresh(!refresh)
    setMasterMerchantX(masterMerchant)
  };

  const EditView = (masterMerchantId) => {
    setShow3(true)
    setCurrentId(masterMerchantId)
    setRefresh(!refresh)
  };

  const EditPhase2 = (masterMerchantId, masterMerchant) => {
    setShowPhase2(true)
    setCurrentId(masterMerchantId)
    setMasterMerchantX(masterMerchant)
    setRefresh(!refresh)
  };

  const columns = [
    {
      headerName: "Master Merchant Name",
      field: "name",
      minWidth: 300,
    },
    {
      headerName: "Prefix",
      field: "midprifix",
      minWidth: 100,
    },
    {
      headerName: "e-Mail",
      field: "email",
      minWidth: 250,
    },
    {
      headerName: "Mobile Number",
      field: "mobile",
      minWidth: 250,
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 50,
    },
    // {
    //   headerName: "Enable / Disable",
    //   field: "active",
    //   minWidth: 200,
    // },

  ];

  const handleClick = () => {
    setShow2(true);
    setAnchorEl(null); // Close the menu when clicking on Phase 1
  }

  const handleOnClick = () => {
    setShow2(true)
  }

  const handleClick2 = () => {
    setShowPhase2(true);
    // setAnchorEl(null); // Close the menu when clicking on Phase 2
  }

  const handleClick3 = () => {
    setShowPhaseEdit(true);
    // setAnchorEl(null); // Close the menu when clicking on Phase 2
  }
  

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);

  const handleCloseViewPhase2 = () => setShowViewPhase2(false);
  const handleClosePhase2 = () => setShowPhase2(false);
  const handleClosePhaseEdit = () => setShowPhaseEdit(false);
  

  return (
    <div className="d-flex justify-content-end">
      <div className="w-75 m-4" >
        <div className="container-fluid" >
         
          <div className="my-4 mx-3">
            <div className="d-flex justify-content-between align-items-center">

              <div className="fs-2 text-black" style={{ fontWeight: "600" }}>
                Master Merchant
              </div>

              <div>
                <PersonAddAlt1Icon fontSize="large" onClick={handleOnClick} />
                {/* <Popper open={Boolean(anchorEl)} anchorEl={anchorEl}>
                  <Paper>
                    <MenuList>
                      <MenuItem onClick={handleOnClick}>Create Master Merchant</MenuItem>
                    </MenuList>
                  </Paper>
                </Popper> */}
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="col-12">
                  <div className="card-body">
                    <div className="table-responsive">
                      <DataGrid
                        rows={submittedAggregator}
                        columns={columns.map((column) => ({
                          ...column,
                          renderCell: (params) =>
                            column.field === "action"
                              ? params.value
                              : params.value,
                        }))}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewSingleSubmittedAggregator
          show={show}
          onHide={handleClose}
          id={currentid}
          refresh={refresh}
        />

        <ViewMasterMerchantPhase2
          show={showViewPhase2}
          onHide={handleCloseViewPhase2}
          id={currentid}
          refresh={refresh}
          masterMerchantX={masterMerchantX}
        />

        <CreateMasterMerchant
          show={show2}
          onHide={handleClose2}
          setRefresh={setRefresh}
          refresh={refresh}
        />

        <EditCreateMasterMerchantPhase2
          id={currentid}
          show={showPhase2}
          onHide={handleClosePhase2}
          setRefresh={setRefresh}
          refresh={refresh}
          masterMerchantX={masterMerchantX}
        />

        {/* <EditCreateMasterMerchantPhase3
          show={showPhaseEdit}
          onHide={handleClosePhaseEdit}
          setRefresh={setRefresh}
          refresh={refresh}
        /> */}

        <MasterMerchantEdit
          show={show3}
          onHide={handleClose3}
          id={currentid}
          setRefresh={setRefresh}
          refresh={refresh}
          setCurrentId={setCurrentId}
        />
      </div>
    </div>
  );
};

export default MMSubmitted;