import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import swal from "sweetalert";
import useApiServices from "../../utils/useApiServices";
import {
  MASTER_MERCHANT_PHASE2,
  SETTLEMENT_TYPE_GET,
  GET_ALL_VEHICLE_TYPE_WITH_ACTIVE,
  EDIT_MASTER_MERCHANT_PHASE2,
} from "../../utils/constant";
import DropDowns from "./DropDowns";

const offcanvasStyle = {
  width: '90vw',
};

const EditCreateMasterMerchantPhase2 = ({ id, show, onHide, refresh, masterMerchantX }) => {
  const [mastermerchantData, setMastermerchntData] = useState({});
  const [settlementData, setSettlementData] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [serviceDetails, setServiceDetails] = useState({});
  const [selectedPaymentSubTypes, setSelectedPaymentSubTypes] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [selectedType1, setSelectedType1] = useState(null);
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const [formDataInputs, setFormDataInputs] = useState({
    masterMerchantId: '',
    masterMerchantName: '',
    settlementTypeName: '',
    settlementTypeId: '',
    services: [],
  });

  const getPhase2Data = async () => {
    try {
      const response = await apiCallFnforGet(MASTER_MERCHANT_PHASE2 + id);
      console.log("editttt", response);
      if (response.statusCode === 200) {
        const data = response.data;
        console.log(data.settlementId? data.settlementId: "")
        setSelectedType1(data.settlementId? data.settlementId: "")
        setMastermerchntData({
          masterMerchantId: data.masterMerchantId,
          masterMerchantName: data.masterMerchantName,
          settlementTypeName: data.settlementTypeName,
          settlementTypeId: (data.settlementTypeName === "Master merchant wise" ? 1 : 2),
          services: data.services
        });
      } else {
        setMastermerchntData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  

  const handleSubmit1 = async () => {
    const services = selectedPaymentSubTypes.map((service) => ({
      // serviceId: "service",
      serviceId: paymentTypes
        ?.find(paymentType => paymentType.paymentSubTypedtos
          ?.find(type => type.paymentSubService === service)
        )
        ?.paymentSubTypedtos
        ?.find(type => type.paymentSubService === service)
        ?.paymentSubTypeId,
      fixAmount: Number(serviceDetails[service]?.fixedAmount) || 0,
      percentage: Number(serviceDetails[service]?.percentage) || 0,
      mdrValue: Number(serviceDetails[service]?.mdr) || 0,
    }));

    const hasInvalidFixedAmount = services.some((item) => {
      const fixAmount = parseFloat(item.fixAmount);
      return isNaN(fixAmount) || fixAmount < 0;
    });

    const hasInvalidPercentage = services.some((item) => {
      const percentage = parseFloat(item.percentage);
      return isNaN(percentage) || percentage < 0 || percentage > 100;
    });

    const hasInvalidMDR = services.some((item) => {
      const mdrValue = parseFloat(item.mdrValue);
      return isNaN(mdrValue) || mdrValue < 0 || mdrValue > 100;
    });

    if (hasInvalidFixedAmount || hasInvalidPercentage || hasInvalidMDR) {
      swal({
        title: "Alert!",
        text: "Please ensure Fixed Amount is a positive value, and Percentage and MDR are within the range of 0 to 100.",
        icon: "error",
      });
      return;
    }


    const selectedSettlement = settlementData.find((settlement) => settlement.name === selectedType.settlementId);

    // if (!selectedSettlement) {
    //   swal({
    //     title: "Alert!",
    //     text: "Selected settlement type is not valid.",
    //     icon: "error",
    //   });
    //   return;
    // }

    const payload = {
      services,
      settlementTypeId: selectedType1 || 0,
      masterMerchantId: mastermerchantData.masterMerchantId || masterMerchantX.masterMerchantId || 0,
    };

    //   const payload =  {
    //     "services": [
    //         {
    //             "serviceId": 22,
    //             "fixedAmount": 0,
    //             "percentage": 2,
    //             "mdr": 0
    //         }
    //     ],
    //     "settlementTypeId": 2,
    //     "masterMerchantId": 3268990
    // }
    // const payload = {
    //   services,
    //   settlementTypeName: mastermerchantData.settlementTypeName || 0,
    //   settlementTypeId: settlementTypeId || 0
    // };

    try {
      const response = await apiCallFnforPost(EDIT_MASTER_MERCHANT_PHASE2 + id, payload);
      if (response.statusCode === 200) {
        swal("Success!", "Data saved successfully.", "success");
        handleClose();
      } else {
        swal("Error!", "Something went wrong.", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(mastermerchantData);

  useEffect(() => {
    getPhase2Data();
  }, [id, refresh]);

  useEffect(() => {
    GetAllSettlementData();
    GetVehicleType();
  }, []);

  useEffect(() => {
    if (Object.keys(mastermerchantData).length > 0) {
      const serviceMapping = {};
      mastermerchantData.services.forEach(service => {
        serviceMapping[service.paymentSubTypeType] = {
          fixedAmount: service.fixAmount,
          percentage: service.percentage,
          mdr: service.mdrValue,
        };
      });
      console.log(serviceMapping)
      setServiceDetails(serviceMapping);
      console.log(serviceDetails.AEPS)

      setFormDataInputs({
        masterMerchantId: mastermerchantData.masterMerchantId,
        masterMerchantName: mastermerchantData.masterMerchantName,
        settlementTypeName: mastermerchantData.settlementTypeName,
        settlementTypeId: mastermerchantData.settlementTypeId,
        services: serviceMapping,
      });

      setSelectedPaymentSubTypes(mastermerchantData.services.map(service => service.paymentSubTypeType));

    }
  }, [mastermerchantData]);

  const GetAllSettlementData = async () => {
    try {
      const response = await apiCallFnforGet(SETTLEMENT_TYPE_GET);
      if (response.statusCode === 200) {
        setSettlementData(response?.data);
      } else {
        setSettlementData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetVehicleType = async () => {
    try {
      const response = await apiCallFnforGet(GET_ALL_VEHICLE_TYPE_WITH_ACTIVE);
      if (response.statusCode === 200) {
        setPaymentTypes(response?.data);
      } else {
        setPaymentTypes([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckboxChange = (paymentSubService) => {
    setSelectedPaymentSubTypes((prev) =>
      prev.includes(paymentSubService)
        ? prev.filter((item) => item !== paymentSubService)
        : [...prev, paymentSubService]
    );

    setServiceDetails((prev) => {
      const newDetails = { ...prev };
      if (newDetails[paymentSubService]) {
        delete newDetails[paymentSubService];
      }
      return newDetails;
    });
  };

  const handleInputChange = (paymentSubService, field, value) => {
    setServiceDetails((prev) => ({
      ...prev,
      [paymentSubService]: {
        ...prev[paymentSubService],
        [field]: value
      }
    }));
  };

  const handleTypeChange = (event) => {
    console.log(event)
    const selectedId = parseInt(event.target.value);
    setSelectedType((prev) => ({ ...prev, settlementId: selectedId }));
  };


  const handleClose = () => {
    onHide();
  };

  console.log("y rha settlement type-->", selectedType1)

  const handleEdit = (value) => {
    console.log("VALS==>", value)
  }

  const FindServiceType =(val)=>{
    const service = paymentTypes?.find((res) => 
      res.paymentSubTypedtos.find((obj) => obj.paymentSubTypeId === val)
    )?.paymentService;
    
    // console.log(service);
    return service?.toUpperCase();
  }

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        style={offcanvasStyle}
      >
        <div className="d-flex justify-content-start align-items-center">
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <h5 className="offcanvas-title fs-2 text-dark fw-bold" id="offcanvasLabel">
            Edit Master Merchant Phase 2
          </h5>
        </div>

        <Offcanvas.Body>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div className="">
                <div className="card-body py-4">
                  <div id="" className="form-wizard order-create">
                    <div id="" className="tab-pane" role="tabpanel">
                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark fs-4">
                              Master Merchant Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="merchantname"
                              disabled
                              value={mastermerchantData?.masterMerchantName || masterMerchantX?.name}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2">
                          <div className="mb-3">
                            <label className="mb-1 text-dark fs-4">
                              Settlement Type
                            </label>
                            <select
                              name="settlementId"
                              className="form-control"
                              value={selectedType1 || ''} // Ensure the default value is handled
                              onChange={(e) => setSelectedType1(parseInt(e.target.value, 10))} // Convert value to integer
                            >
                              <option value="">Select</option>
                              {settlementData?.map((item, i) => (
                                <option key={i} value={item.id? item.id: ""}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <table className="table">
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Service Type</th>
                              <th>Sub-service Type</th>
                              <th>Fixed Amount (monthly)</th>
                              <th>Percentage (monthly)</th>
                              <th>MDR (percentage)</th>
                            </tr>
                          </thead>

                          <tbody>
                            {paymentTypes?.map((paymentType, i) =>
                              paymentType?.paymentSubTypedtos?.map((paymentSubType, j) => (
                                <tr key={`${i}-${j}`}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="typeOfServices"
                                      value={paymentSubType.paymentSubService}
                                      checked={selectedPaymentSubTypes.includes(
                                        paymentSubType.paymentSubService
                                      )}
                                      onChange={() => handleCheckboxChange(paymentSubType.paymentSubService)}
                                    />
                                  </td>
                                  <td className="fw-bolder">{FindServiceType(paymentSubType?.paymentSubTypeId)}</td>
                                  <td>{paymentSubType.paymentSubService}</td>
                                  <td>
                                    <input
                                      name="fixedAmount"
                                      type="number"
                                      placeholder="Fixed Amount"
                                      className="form-control"
                                      value={`${serviceDetails[paymentSubType.paymentSubService]?.fixedAmount}`}
                                      // onChange={(e)=>handleEdit(e.target.value)}
                                      onChange={(e) => handleInputChange(paymentSubType.paymentSubService, 'fixedAmount', e.target.value)}
                                      disabled={!selectedPaymentSubTypes.includes(paymentSubType.paymentSubService)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name="percentage"
                                      type="number"
                                      placeholder="Percentage"
                                      className="form-control"
                                      value={`${serviceDetails[paymentSubType.paymentSubService]?.percentage}`}
                                      onChange={(e) => handleInputChange(paymentSubType.paymentSubService, 'percentage', e.target.value)}
                                      disabled={!selectedPaymentSubTypes.includes(paymentSubType.paymentSubService)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      name="mdr"
                                      type="number"
                                      placeholder="MDR"
                                      className="form-control"
                                      value={`${serviceDetails[paymentSubType.paymentSubService]?.mdr}`}
                                      onChange={(e) => handleInputChange(paymentSubType.paymentSubService, 'mdr', e.target.value)}
                                      disabled={!selectedPaymentSubTypes.includes(paymentSubType.paymentSubService)}
                                    />
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-end">
                          <button
                            className="badge badge-rounded badge-primary mx-2 px-3 py-3"
                            onClick={handleSubmit1}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default EditCreateMasterMerchantPhase2;