import React from "react";

const DropDowns = ({
  fieldName,
  Name,
  HandleChange,
  SelectedType,
  AllTypes,
}) => {
 console.log("type", AllTypes);
  return (
    <>
      <div className="">
        <div className="">
        <label className="mb-1 text-dark fs-4">
            {fieldName}
            <sup className="text-danger">*</sup>
          </label>
          <select
            id={Name}
            name={Name}
             
            className="form-control"
            onChange={HandleChange}
            value={SelectedType}
          >
            <option value="">Select</option>
            {AllTypes?.map((item, i) => (
              <option key={i} value={ item?.Id }>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default DropDowns;
